import Fade from "react-reveal/Fade";
import Typography from "@mui/material/Typography";
import ReadMore from "./ReadMore";
import "./team.css";
export const Team = (props) => {
  return (
    <div id="team">
      <Fade bottom cascade>
        <div className="section-title">
          <Typography variant="h1" className="h1Text">
            Meet the Team
          </Typography>
          <Typography
            variant="h6"
            className="section-text"
            style={{ marginTop: "20px" }}
            gutterBottom
          >
            At Produce Capital, we believe your success is directly linked to
            our team’s ability to understand your objectives - connect your
            ideas - and along with our resources and approach, reach positive
            results that you can expect and be proud of. Whether you are an
            individual or small-mid cap business, we have the connections and
            resources you will need to protect, sustain and expand your
            portfolio. With direct access to foreign government leaders and
            global business decision-makers, expansions of your operations into
            foreign markets are made possible.
          </Typography>
          <Typography
            variant="h6"
            className="section-text"
            style={{ marginTop: "20px" }}
            gutterBottom
          >
            Our teams are comprised of young and mature professionals astute
            their fields which facilitates an open exchange of ideas and forward
            thinking. We believe in honesty, diversity, long-term relationships
            and are committed to excellence.
          </Typography>
        </div>
      </Fade>
      <Fade bottom cascade>
        {/* need to add the border */}
        <section className="teamContainer">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="section-item-team">
                  <div className="section-col-team">
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      <hr />
                    </div>
                    <Typography
                      variant="p"
                      className="description"
                      gutterBottom
                    >
                      <ReadMore>{d.description}</ReadMore>
                    </Typography>
                  </div>
                </div>
              ))
            : "loading"}
        </section>
      </Fade>
    </div>
  );
};
