import './contact.css'
const Subscription = () => {
  return (
    <div className="container contact-content">
      <div className="sub-content">
        <div className="sub">
          <p className="text-center sub-action">
            Subscribe to our Week Email A weekly email from Produce Capital
            about trends shaping markets, industries and the global economy.
          </p>
        </div>

        <div className="form-input">
          <form className="form-inline">
            <input
              className="form-control mr-sm-2 form-input-box"
              type="search"
              placeholder="Subscribe"
              aria-label="Search"
            />
            <button
              className="btn btn-primary my-2 my-sm-0"
              type="submit"
              style={{
                height: '50px',
                width: '300px',
              }}
            >
              SUBSCRIBE
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default Subscription
