import React, { useState } from 'react'
import './readMore.css'
const ReadMore = ({ children }) => {
  const text = children
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 250) : text}
      <span className="read-or-hide" onClick={toggleReadMore}>
        {isReadMore ? '...read more' : ' show less'}
      </span>
    </p>
  )
}
export default ReadMore
