import { useState } from 'react'
import emailjs from 'emailjs-com'
import './contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () =>
    setState({
      name: '',
      email: '',
      message: '',
    })
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_gm9c43s',
        'template_1fu1rts',
        e.target,
        'user_WHx1fQwwaqrrTn4a6xFfx'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          alert('thank you for submitting! We will contact you soon!')
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <>
      <div id="contact" style={{ backgroundColor: '#F3F3F3' }}>
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title-contact">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <div>
                  <button type="submit" className="btn btn-primary btn-lg">
                    SEND MESSAGE
                  </button>
                  {/* <p>we got your message!</p> */}
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <FontAwesomeIcon
                    icon={faMapMarker}
                    style={{ fontSize: '20px' }}
                  />
                  {'     '} Address
                </span>
                <a
                  href="https://goo.gl/maps/srgdpWmQxmerJDFQA"
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.data ? props.data.address1 : 'loading'}
                </a>
                <br></br>
                <br></br>
                <a
                  href="https://goo.gl/maps/U3nVqEmUJEHcgUet9"
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.data ? props.data.address2 : 'loading'}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p
                onClick={() => {
                  window.open(`tel:${props.data.phone}`)
                }}
              >
                <span>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: '20px' }}
                  />
                  {'     '} Phone
                </span>
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className="contact-item">
              <p
                onClick={() => {
                  window.open('mailto:info@producec.com')
                }}
              >
                <span>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ fontSize: '20px' }}
                  />
                  {'     '} Email
                </span>
                {props.data ? props.data.email : 'loading'}
              </p>
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{ fontSize: '50px' }}
                      />
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : '/'}>
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ fontSize: '50px' }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{ fontSize: '50px' }}
                      />
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : '/'}>
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ fontSize: '50px' }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}
