import React from 'react'
import './footer.css'
export default function footer() {
  return (
    <div id="footer">
      <div className="container text-center">
        <p>
          &copy; {new Date().getFullYear()} Produce Capital. All right reserved.
          Powered by <a href="http://www.classait.com">Class A IT</a>
        </p>
      </div>
    </div>
  )
}
//  <section
//         className="main-content container"
//         style={{ backgroundColor: "white" }}
//       >
//         <div className="main-content-ss">
//           <img className="img-logo" src="img/logo.png" href="#page-top" />
//         </div>

//         <div className="main-content-item">
//           <ul className="main-content-item-list">
//             <li>
//               <a className="item-list-link">Privacy and Cookies</a>
//             </li>
//             <li>
//               <a className="item-list-link">Ad Choices</a>
//             </li>
//             <li>
//               <a className="item-list-link">Terms & Conditions</a>
//             </li>
//             <li>
//               <a className="item-list-link">Security & Fraud Awareness</a>
//             </li>
//             <li>
//               <a className="item-list-link">Regulatory Disclosures</a>
//             </li>
//           </ul>
//         </div>
//         <div className="main-content-item">
//           <ul className="main-content-item-list">
//             <li>
//               <a className="item-list-link">Privacy and Cookies</a>
//             </li>
//             <li>
//               <a className="item-list-link">Ad Choices</a>
//             </li>
//             <li>
//               <a className="item-list-link">Terms & Conditions</a>
//             </li>
//             <li>
//               <a className="item-list-link">Security & Fraud Awareness</a>
//             </li>
//             <li>
//               <a className="item-list-link">Regulatory Disclosures</a>
//             </li>
//           </ul>
//         </div>
//       </section>
