import React from 'react'
import s from './header-nav.module.scss'
const HeaderNav = React.forwardRef(({ items, isMenuOpen, toggleNav }, ref) => {
  const openClass = isMenuOpen ? s.open : ''

  return (
    <nav className={`${s.nav} ${openClass}`} ref={ref}>
      {items.map((item) => (
        <a
          href={`#${item.slug}`}
          key={item.id}
          className={`${s.nav__item} page-scroll`}
          ref={item.ref}
          onClick={toggleNav}
        >
          {item.name}
        </a>
      ))}
    </nav>
  )
})

export default HeaderNav
