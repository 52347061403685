const navigation = [
  {
    id: '5258d430-d729-44b1-96ad-48sdsdsdsd',
    name: 'home',
    slug: 'home',
  },
  {
    id: '5258d430-d729-44b1-96ad-484c005d',
    name: 'about',
    slug: 'about',
  },
  {
    id: '94596dd2-3e95-4587-bdfd-4f48af31b06d',
    name: 'services',
    slug: 'services',
  },
  {
    id: '94596dd2-3e95-4587-bdfd-kljdslkdj',
    name: 'Our Team',
    slug: 'team',
  },
  {
    id: '2fa83d93-e460-4298-a07d-70d0b3642397',
    name: 'contact',
    slug: 'contact',
  },
]

export default navigation
