import 'react-image-gallery/styles/scss/image-gallery.scss'
import 'react-image-gallery/styles/css/image-gallery.css'
import './imageSilder.css'

import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import Fade from 'react-reveal/Fade'

import img4 from './imageSlides/4.jpeg'
import img2 from './imageSlides/2.jpg'
const ImageSlider = () => {
  return (
    <div id="home">
      <section id="#top">
        <Slide easing="ease" arrows={false}>
          <div className="each-slide">
            <img src={img4} alt=" silde 1" />
          </div>
          <div className="each-slide">
            <img src={img2} alt=" silde 2" />
          </div>
        </Slide>
      </section>
      <div className="banner">
        <Fade bottom cascade>
          <div className="bannerHeader">
            <h1 className="bannerHeader"> Inventing the </h1>
            <h1> Future of Finance</h1>
          </div>
        </Fade>
        <Fade>
          <p>
            We invest in Discretion Long/Short, Macro, and Systematic
            strategies. We're inventing the future of finance by revolutionzing
            how we delovelop our people and how we use data to shape our
            thinking.{' '}
            <span
              onClick={() => {
                window.open('mailto:info@producec.com')
              }}
            >
              <a href="/#" target="_blank" rel="noreferrer">
                Join our team{' '}
              </a>
            </span>
            to innvoate, experiment, and be the best at what you do.
          </p>
        </Fade>
      </div>
    </div>
  )
}

export default ImageSlider
