import { useState, useEffect } from 'react'
import Navigation from './components/navigation'
import { Header } from './components/header'
// import { Features } from './components/features'
import { About } from './components/about'
import { Services } from './components/services'
// import { Gallery } from './components/gallery'
// import { Testimonials } from './components/testimonials'
import { Team } from './components/Team'
import { Contact } from './components/contact'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import ImageSlider from './components/imageSilder'
import Subscription from './components/Subscription'
import Footer from './components/footer'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1500,
  speedAsDuration: true,
  offset: 90,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
      }}
    >
      <Navigation />
      <Header data={landingPageData.Header} />
      <ImageSlider />
      <About />
      <Services data={landingPageData.Services} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      <Subscription />
      <Footer />
    </div>
  )
}

export default App
