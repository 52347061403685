import React from "react";
import s from "./header-logo.module.scss";
import logo from "../logo.png";
const HeaderLogo = () => {
  return (
    <div className={s.logo}>
      <a href={`#home`}>
        <img src={logo} className={s.logoImg} alt="logo" />
      </a>
    </div>
  );
};

export default HeaderLogo;
