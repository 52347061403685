import './service.css'
import investmentPic from './servicesImages/services-2.jpg'
import consultingPic from './servicesImages/services-3.jpg'
import assetMgtPic from './servicesImages/assetMgt_img.png'
import maPic from './servicesImages/m&a.jpeg'
import img4 from './servicesImages/services-4.jpg'
import Fade from 'react-reveal/Fade'
import Typography from '@mui/material/Typography'

export const Services = (props) => {
  return (
    <div id="services">
      <section style={{ textAlign: 'center' }} className="servicesTitle">
        <Typography variant="h1" className="h1Text">
          Our Services
        </Typography>
      </section>

      <section className="section-item">
        <Fade bottom cascade>
          <div className="section-col section-content">
            <Typography variant="h6" className="section-titleH1">
              Consulting
            </Typography>
            <Typography variant="h6" className="section-text" gutterBottom>
              Our consulting division is dedicating to providing relevant and
              accurate data to facilitate decision making from an individual to
              an institutional level. Whether your needs require valuation
              models, business plan preparation, or due diligence for an IPO, we
              are committed to helping you achieve efficiency, profitability,
              and security through detailed analysis for sustainable growth.
            </Typography>
          </div>
        </Fade>
        <div className="section-col section-img">
          <Fade bottom>
            <img src={consultingPic} className="mkt-img" alt="services 1" />
          </Fade>
        </div>
      </section>

      <section className="section-item section-item-2">
        <div className="section-col">
          <Fade bottom>
            <img src={investmentPic} className="mkt-img" alt="services 2" />
          </Fade>
        </div>

        <div className="section-col section-content">
          <Fade bottom cascade>
            <Typography variant="h6" className="section-titleH1">
              Investment Opportunity
            </Typography>
            <Typography variant="h6" className="section-text" gutterBottom>
              Produce Capital continues to engage in a board range of investment
              activities on a global basis. We invest in Seed and Series A
              startups, and are committed to helping companies throughout the
              growth stage. For the past 3 years, we have concentrated on
              industries in:
            </Typography>
            <div className="orderList">
              <ol
                style={{
                  columns: 2,
                  listStyleType: 'square',
                  width: '100%',
                }}
              >
                <li>Retail Store</li>
                <li>Mining</li>
                <li>High Tech</li>
                <li>Cemetery</li>
                <li>Education</li>
                <li>Real Estate</li>
                <li>Venture Capital</li>
                <li>Charity</li>
                <li>Defense Industry</li>
              </ol>
            </div>
          </Fade>
        </div>
      </section>

      <section className="section-item section-item-3">
        <div className="section-col section-content">
          <Fade bottom cascade>
            <Typography variant="h6" className="section-titleH1">
              Security Trading
            </Typography>

            <Typography variant="h6" className="section-text" gutterBottom>
              Our clients expect their portfolio’s to generate maximum profits
              with minimal risk. Thus, our securities trading division has a
              professional team to launch cutting edge investment strategies to
              adjust to the ever changing market conditions. Our platform is
              based on a multi-strategy methodology that consists of a top-down
              approach combined with fundamental and event driven tactics. This
              allows our trading team to best determine the proper course of
              action for our clients keeping one goal in mind, to continually
              outperform the market.
            </Typography>
          </Fade>
        </div>

        <div className="section-col section-img">
          <Fade bottom>
            <img src={img4} className="mkt-img" alt="services 3" />
          </Fade>
        </div>
      </section>
      <section className="section-item section-item-4">
        <div className="section-col section-img">
          <Fade bottom>
            <img src={assetMgtPic} className="mkt-img" alt="services 4" />
          </Fade>
        </div>
        <div className="section-col section-content">
          <Fade bottom cascade>
            <Typography variant="h6" className="section-titleH1">
              Asset Management
            </Typography>
            <Typography variant="h6" className="section-text" gutterBottom>
              Our company helps clients to manage their assets that include:
              portfolio analysis, market forecasting, investment advice,
              implementation of investment strategies, and performance analysis
              with our professional background and expertise. We understand our
              clients’ needs and take a long-term approach to create client
              specific solutions. We are dedicated to delivering innovative,
              comprehensive and coordinated financial services for our clients,
              and producing long-term financial well being without exposing
              their capital to excessive risk. Our goals are to lookout for the
              financial health of our clients and provide quality service that
              will set us apart from the competition.
            </Typography>
          </Fade>
        </div>
      </section>
      <section
        className="section-item section-item-5"
        style={{ paddingBottom: '100px' }}
      >
        <Fade bottom cascade>
          <div className="section-col section-content">
            <Typography variant="h6" className="section-titleH1">
              Mergers & Acquisitions
            </Typography>
            <Typography variant="h6" className="section-text" gutterBottom>
              Produce Capital is committed to operating with the upmost
              responsibility and honesty with respects to corporate strategy.
              Our M&A group are capable of advising any enterprise that is
              looking to grow rapidly from either horizontal, vertical, or
              conglomeration integration. We understand that accurate business
              valuations are vital to successful mergers and acquisitions.
              That’s why our M&A group are devoted to providing our clients with
              the most accurate information, so they can receive the fairest
              price for their project.
            </Typography>
          </div>
        </Fade>
        <div className="section-col section-img">
          <Fade bottom>
            <img src={maPic} className="mkt-img" alt="services 5" />
          </Fade>
        </div>
      </section>
    </div>
  )
}
