import "./about.css";
import Fade from "react-reveal/Fade";
import Typography from "@mui/material/Typography";
export const About = () => {
  return (
    <div id="about">
      <div className="containerAbout">
        <Fade bottom cascade>
          <Typography
            variant="h3"
            className="h1Text"
            style={{ marginBottom: "20px" }}
          >
            Who We Are
          </Typography>

          <Typography variant="h4" className="text" gutterBottom>
            Produce Capital is an experienced and trusted investment and asset
            management group that focuses on investment opportunities with
            minimal risk. Our teams are committed to making the best decisions
            that will benefit your assets for sustainable growth. Our fund
            manager oversees all recommended investment advice, allowing for a
            streamlined management system that is proven and clear. We welcome
            individual, institutional, and government clients to invest in our
            professional and experienced teams.
          </Typography>

          <Typography
            variant="h4"
            className="text"
            gutterBottom
            style={{ marginTop: "20px" }}
          >
            Our purpose is to help individuals and institutions navigate the
            murky waters of investing. We believe it is paramount that people
            have a friend who can genuinely troubleshoot their financial
            problems and set them on a path of security. We believe people need
            an expert with comprehensive knowledge to provide financial analysis
            of relevant projects. Finally, we believe people need a partner who
            will put the investor in the same boat and bind common profits with
            risk to facilitate trust. That’s why at Produce Capital, we take
            investing seriously and put our clients first. We are here to help
            you.
          </Typography>
        </Fade>
      </div>
    </div>
  );
};
